import { HttpClient, HttpHeaders, HttpParams, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { AccountData, HoldingData } from 'src/app/models/account-grouping.model';
import { CreateFormObject } from 'src/app/models/create-group.model';
import { environment } from 'src/environments/environment';
import { AccountGroupingDataService } from '../account-grouping-data/account-grouping-data.service';
import { PwViewsFormsService } from '../pw-views-forms/pw-views-forms.service';
import {
  AccountPayload,
  createViewPayload,
} from 'src/app/interfaces/create-account-grouping.interface';

@Injectable({
  providedIn: 'root',
})
export class PwViewsModifyService {
  allInvestments: AccountData[];
  editObject: BehaviorSubject<CreateFormObject> = new BehaviorSubject<CreateFormObject>(
    {} as CreateFormObject,
  );
  xsrfToken: string;
  tokenHeader: HttpHeaders;
  editEndpointURL: string;
  deleteEndpointURL: string;
  private _lastModifiedAccountGroupName: string;

  constructor(
    private httpClient: HttpClient,
    private token: HttpXsrfTokenExtractor,
    private formsService: PwViewsFormsService,
    private accountGroupingDataService: AccountGroupingDataService,
  ) {
    this.xsrfToken = this.token.getToken() ? this.token.getToken()! : 'X-XSRF-TOKEN-DUMMY';
    this.tokenHeader = new HttpHeaders({
      'X-XSRF-TOKEN': this.xsrfToken,
      'Content-type': 'application/json',
    });
    this.editEndpointURL = environment.editEndpointURL;
    this.deleteEndpointURL = environment.deleteEndpointURL;
  }

  // Convert edit object and save portfolio watch view.
  editAccountGroup(): Observable<any> {
    let currentEditObject: CreateFormObject = this.editObject.getValue();
    let pwViewsPayload: any = this.mapEditObjectToPayload(currentEditObject);

    let payload: any = JSON.stringify({ portfolioWatchViews: [pwViewsPayload] });
    return this.httpClient
      .put(this.editEndpointURL, payload, {
        withCredentials: true,
        headers: this.tokenHeader,
      })
      .pipe(
        tap((res) => {
          this.accountGroupingDataService.loadViewsData();
          this.setupLastModifiedAccountGroup();
          return res;
        }),
        catchError((error) => {
          console.log('errored here', error);
          return error;
        }),
      );
  }

  deleteAccountGroup(accGrpViewId: string): Observable<any> {
    const params: HttpParams = new HttpParams().set('clientViewIds', accGrpViewId);

    return this.httpClient
      .delete(this.deleteEndpointURL, {
        withCredentials: true,
        headers: this.tokenHeader,
        params: params,
      })
      .pipe(
        tap((res) => {
          this.accountGroupingDataService.loadViewsData();
          return res;
        }),
        catchError((error) => {
          console.log('errored here', error);
          return error;
        }),
      );
  }

  mapEditObjectToPayload(editObj: CreateFormObject): any {
    let selectedAccounts: AccountPayload[] = this.getSelectedAccounts(editObj);
    let mappedPayload: createViewPayload = {
      clientViewId: editObj.clientViewId as string,
      primaryPortfolioWatchView: false,
      vistaAccount: false,
      viewName: editObj.viewName,
      accounts: selectedAccounts,
      groupType: 'CUSTOM',
    };
    return mappedPayload;
  }

  getSelectedAccounts(editObj: CreateFormObject): AccountPayload[] {
    let allInvestments: AccountData[] = JSON.parse(
      JSON.stringify(this.formsService.allInvestments),
    );

    let selectedAccounts: AccountData[] = allInvestments.filter((account: AccountData) => {
      let allHoldingsIncluded: boolean = !!editObj.accounts.find(
        (acct) => acct.accountId === account.accountId,
      )?.allHoldingsIncluded;
      let someHoldingsIncluded: boolean = !!editObj.accounts.find(
        (acct) => acct.accountId === account.accountId,
      )?.someHoldingsIncluded;
      return allHoldingsIncluded || someHoldingsIncluded;
    });

    selectedAccounts = this.extractAccountsForVistaAccounts(selectedAccounts);

    let selectedAccountsWithSelectedHoldings: AccountPayload[] = selectedAccounts.map(
      (account: AccountData) => {
        const actualHoldingsLength: number = account.accountPositionIds.length;
        let selectedHoldings: HoldingData[] = account.accountPositionIds.filter(
          (holding: HoldingData) => {
            let isHoldingSelected: boolean = account.productType === "PARTICIPANT" ? !!editObj.accounts
              .find((acct) => acct.accountId === account.accountId)
              ?.holdings.find((holdingRef) => holdingRef.holdingName === holding.positionName)
              ?.isHoldingSelected : !!editObj.accounts
              .find((acct) => acct.accountId === account.accountId)
              ?.holdings.find((holdingRef) => holdingRef.holdingId === holding.positionId)
              ?.isHoldingSelected;

            return isHoldingSelected;
          },
        );
        const selectedHoldingsLength: number = selectedHoldings.length;

        let acctPayload: AccountPayload = {
          accountId: account.accountId,
          accountPositionIds: selectedHoldings.map((holding) => {
            return { positionId: holding.positionId };
          }),
          accountType: account.productType,
          includeAllPositions: actualHoldingsLength === selectedHoldingsLength ? 'Y' : 'N',
        } as AccountPayload;
        acctPayload.accountPositionIds =
          acctPayload.includeAllPositions === 'Y' ? [] : acctPayload.accountPositionIds;

        return acctPayload;
      },
    );

    return selectedAccountsWithSelectedHoldings;
  }

  extractAccountsForVistaAccounts(accounts: AccountData[]): AccountData[] {
    return accounts?.filter((account) => !account.isVistaAccount);
  }

  updateEditObject(editObj: CreateFormObject): void {
    this.editObject.next(editObj);
  }

  getEditObject(): Observable<CreateFormObject> {
    return this.editObject.asObservable();
  }

  setupLastModifiedAccountGroup(): void {
    this.lastModifiedAccountGroup = this.editObject.getValue().viewName;
  }

  set lastModifiedAccountGroup(groupName: string) {
    this._lastModifiedAccountGroupName = groupName;
  }

  get lastModifiedAccountGroup(): string {
    return this._lastModifiedAccountGroupName;
  }
}
